import React from 'react';
import { useSelector } from 'react-redux';
import { ExternalScripts } from 'mm-ui-components';
import {
  getExternalScripts,
  getExperiments,
  getCanonicalUrl,
} from '../../store/config/config.selectors';
import { ExternalScripts as ExternalScriptsType } from '../../store/config/config.reducer';

// TODO(stephen): Once a decision is made on the consent provider, this function should be updated

// const ONE_TRUST_SITES = [
//   'skywayshoutout.com', // FS site with no traffic
//   'ninernoise.com',
// ];

// const TRUST_ARC_SITES = [
//   //'beyondtheflag.com',
//   //'newsight-qa2.qa.minutesvc.com',
// ];

export function getConsentProvider(canonicalUrl: string, experiments: string[] | null): string {
  // if (ONE_TRUST_SITES.some(site => canonicalUrl && canonicalUrl.includes(site))) {
  //   return 'onetrust';
  // }

  // if (TRUST_ARC_SITES.some(site => canonicalUrl && canonicalUrl.includes(site))) {
  //   return 'trustarc';
  // }

  if (experiments && experiments.includes('enable-onetrust|activate')) {
    return 'onetrust';
  }

  return 'didomi';
}

function getOneTrustID(canonicalUrl: string): string {
  if (!canonicalUrl) {
    return '';
  }

  if (canonicalUrl.includes('www.90min.de')) {
    return '01948883-cdc5-73d6-a267-dd1f0a86d169';
  }

  if (canonicalUrl.includes('www.12up.com')) {
    return '01948dbe-3c3f-711a-83c5-4896777dbf3f';
  }

  return '';
}

function getOneTrustScripts(canonicalUrl: string): ExternalScriptsType {
  const oneTrustID = getOneTrustID(canonicalUrl);
  if (!oneTrustID) {
    return [];
  }

  const oneTrustScriptAutoBlock = {
    type: 'URL' as const,
    source: `https://cdn-ukwest.onetrust.com/consent/${oneTrustID}/OtAutoBlock.js`,
    name: 'OTAutoBlock',
    attributes: null,
    loadingType: 'blocking' as const,
  };

  const oneTrustScriptURL = {
    type: 'URL' as const,
    source: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
    name: 'OTURL',
    attributes: {
      'data-domain-script': oneTrustID,
    },
    loadingType: 'blocking' as const,
  };

  // const oneTrustScriptIAB = {
  //   type: TYPE_OPTIONS.URL,
  //   source: 'https://cdn.cookielaw.org/opt-out/otCCPAiab.js',
  //   name: 'OTIAB',
  //   attributes: null,
  //   loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
  // };

  const oneTrustScriptInit = {
    type: 'code' as const,
    source: 'function OptanonWrapper() { }',
    name: 'OTInit',
    attributes: null,
    loadingType: 'blocking' as const,
  };

  return [oneTrustScriptAutoBlock, oneTrustScriptURL, oneTrustScriptInit];
}

export const HeadScripts: React.FunctionComponent = () => {
  const experiments = useSelector(getExperiments);
  const scripts = useSelector(getExternalScripts);
  const canonicalUrl = useSelector(getCanonicalUrl);

  const consentProvider = getConsentProvider(canonicalUrl, experiments);

  if (consentProvider === 'onetrust') {
    scripts.unshift(...getOneTrustScripts(canonicalUrl));
  }

  // Add BlueConic script for all properties on www.si.com
  if (canonicalUrl && canonicalUrl.includes('www.si.com')) {
    scripts.push({
      type: 'URL',
      source: 'https://p329.si.com/script.js',
      name: 'BlueConic',
      attributes: null,
      loadingType: 'async',
    });
  }

  return <ExternalScripts scripts={scripts} consentProvider={consentProvider} />;
};
