import * as React from 'react';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { didomiScript } from './ConsentScript';
import { Kasda } from '../../Kasda/Kasda';

type TYPE = 'URL' | 'code';
export const TYPE_OPTIONS: { URL: TYPE; CODE: TYPE } = {
  URL: 'URL',
  CODE: 'code',
};

type LOADING_TYPE = 'defer' | 'async' | 'blocking';
export const LOADING_TYPE_OPTIONS: {
  DEFER: LOADING_TYPE;
  ASYNC: LOADING_TYPE;
  BLOCKING: LOADING_TYPE;
} = {
  DEFER: 'defer',
  ASYNC: 'async',
  BLOCKING: 'blocking',
};

type Script = {
  attributes: object | null;
  loadingType: LOADING_TYPE;
  name: string;
  source: string;
  type: TYPE;
};

type ExternalScriptsProps = {
  scripts: Array<Script>;
  consentProvider: string;
};

const didomiScriptObject = {
  name: 'Didomi',
  attributes: null,
  loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
  source: didomiScript,
  type: TYPE_OPTIONS.CODE,
};

function createScriptTag({ type, source, name, attributes = {}, loadingType }: Script) {
  if (type === TYPE_OPTIONS.URL && source.length > 0) {
    if (loadingType === LOADING_TYPE_OPTIONS.DEFER) {
      return <script defer src={source} type="text/javascript" key={name} {...attributes} />;
    }
    if (loadingType === LOADING_TYPE_OPTIONS.ASYNC) {
      return <script async src={source} type="text/javascript" key={name} {...attributes} />;
    }
    return <script src={source} type="text/javascript" key={name} {...attributes} />;
  }

  if (type === TYPE_OPTIONS.CODE && source.length > 0) {
    return (
      <script type="text/javascript" key={name} {...attributes}>
        {source}
      </script>
    );
  }

  return null;
}

const removeLegacyOneTrustScripts = (scripts: Array<Script>) => {
  return scripts.filter(script => !script.name.toLowerCase().includes('onetrust'));
};

export const ExternalScriptsComponent = ({ scripts, consentProvider }: ExternalScriptsProps) => {
  const filteredScripts = removeLegacyOneTrustScripts(scripts);

  let consentScripts: Script[] = [];

  if (consentProvider === 'didomi') {
    consentScripts = [didomiScriptObject];
  }

  const finalScripts = [...consentScripts, ...filteredScripts];

  return <Kasda.Script>{finalScripts.map(createScriptTag)}</Kasda.Script>;
};

export const ExternalScripts = (props: ExternalScriptsProps) => (
  <ErrorBoundary>
    <ExternalScriptsComponent {...props} />
  </ErrorBoundary>
);
